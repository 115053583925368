$font-light: 300;
$font-normal-weight: 400;
$font-semi-bold: 500;
$font-bold: 700;

$title-size: 36px;
$title-medium-size: 30px;
$title-line-height: 16px;

$font-small-size: 12px;
$font-size: 14px;
$font-big-size: 15px;
$font-bigger-size: 16px;
$font-icon-size: 20px;
$font-title-size: 21px;
$font-card-title-size: 24px;

$font-color: rgba(0, 0, 0, 0.87);
$font-color-light: rgba(0, 0, 0, 0.54);
$font-color-lighter: grey;
$font-lightest-color: #757575;
$font-color-disabled: rgba(0, 0, 0, 0.38);
$font-color-grey: #808080;
$icon-color: $font-color-light;