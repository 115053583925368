@import './mixins';
@import './color-variables';

.mat-button-disabled {
  background-color: #ccc !important;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel {
  button {
    @include flexbox();
    @include align-items(center);
    width: 200px !important;
    span {
      flex-grow: 1;
    }
    mat-icon {
      color: $primary !important;
      margin-right: 0 !important;
    }
  }

  .infobox {
    @include flexbox();
    @include flex-direction(column);
    background-color: $color-selected;
    padding: 12px;
    max-width: 200px;
    strong {
      text-transform: uppercase;
      color: $primary;
    }
    span {
      margin-top: 5px;
      display: inline-block;
    }
  }
}