@import './mixins';

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
