@import './mat_override';
@import './themes';
@import './color-variables';
@import './reset';
@import './mixins';
@import './dimension-variables';
@import './color-variables';
@import './font-variables';


.grecaptcha-badge { visibility: hidden; }

.content {
  height: 100%;
  width: 100%;
  app-inbox {
    height: calc(100% - 48px);
    width: calc(100vw - 55px);
  }
}

.mat-tab-body-wrapper {
  height: 100%;
}

*::-webkit-scrollbar { display: none !important; }

audio:focus {
    outline: none;
}

mat-dialog-container {
  padding: 0 !important;
  padding-bottom: 24px !important;
  max-width: 35vw;
  min-width: 380px;
}

.administration-form {
  mat-toolbar {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    height: 64px;
    background-color: $agent-primary;
    color: white;
    padding: 0 8px 0 16px;
    .title {
      text-transform: capitalize;
      font-weight: $font-normal-weight;
      @include ellipsis();
    }
    .close-button {
      margin-left: auto;
    }
  }
  mat-dialog-content {
    max-height: 80vh;
    padding: 24px !important;
    margin: 0px !important;
    @include display-scrollbar();
  }
  mat-form-field {
    min-height: 76px !important;
    max-height: 76px !important;
  }
  mat-autocomplete {
    min-height: 78px !important;
    max-height: 78px !important;
  }
  mat-checkbox {
    min-height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 10px;
  }
  .administration-form-checkbox-content {
    height: 0;
    overflow: hidden;
    &.is-open-content {
      height: fit-content !important;
      overflow: initial;
    }
    &-multiple {
      border: 1px solid $grey;
      border-radius: 3px;
      margin-bottom: 30px;
      &-top {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        @include ellipsis();
        margin: 15px;
        height: 17px;
        background-color: $color-background;
        &-buttons {
          margin-left: auto;
        }
      }
      &-detail {
        height: 0;
        overflow: hidden;
        &.is-open {
          height: auto;
          overflow: initial;
          border-top: 1px solid $grey;
        }
        &-container {
          @include flexbox();
          @include flex-direction(column);
        }
      }
    }
  }
  .mat-dialog-title {
    margin: 0;
  }
  .bottom-buttons {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(flex-end);
    padding: 5px 25px;
    button {
      min-width: 88px;
      max-width: fit-content;
    }
  }
}

.mini-fab-button {
  transform: scale(0.8) !important;
}

.smallest-fab-button {
  transform: scale(0.60) !important;
}

.mat-tab-list {
  min-width: 100%;
  .mat-tab-labels {
      .mat-tab-label {
          min-width: 33%;
          max-width: 33%;
          opacity: 1;
          color: $font-color-light;
      }
      .mat-tab-label-active {
          color: $agent-accent;
      }
  }
}

.mat-datepicker-toggle-default-icon {
  width: 20px !important;
}

.mat-tooltip {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

[hidden] {
  display: none !important
}

.background-dark-agent-available-status { background-color: $dark-available-background; }
.background-dark-agent-meeting-status { background-color: $dark-meeting-background; }
.background-dark-agent-outgoing-status { background-color: $dark-outgoing-background; }
.background-dark-agent-on-break-status { background-color: $dark-on-break-background; }
.background-dark-agent-on-call-status { background-color: $dark-on-call-background; }
.background-dark-agent-ringing-status { background-color: $dark-ringing-background; }
.background-dark-agent-logged-out-status { background-color: $dark-grey; }
.mat-focused .mat-form-field-required-marker { color: $agent-primary !important; }
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker { color: red !important; }

.text-danger {
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  color: $red-error;
  font-size: $font-small-size;
  line-height: 14px;
  transition: all .3s cubic-bezier(.55,0,.55,.2);
  span {
    padding-top: 3px;
  }
}
mat-form-field {
  margin-bottom: -1.25em;
}


.mat-form-field-disabled {
  .mat-form-field-infix {
    background: linear-gradient($color-disabled 100%, transparent 0) no-repeat;
  }
}

.mat-progress-bar-fill::after {
  background-color: $agent-primary !important;
}

.mat-progress-bar-buffer {
  background-color: $agent-light-primary !important;
}

.current-conversation-infobox {
  top: 120px;
  left: 55px !important;
}

.current-conversation-info-search-bar {
  $search-margin-left: 26px;
  $search-margin-right: 14px;
  $total-width: 300px;
  $input-margin-left: 20px;
  
  margin-left: $search-margin-left;
  margin-right: $search-margin-right;

  .mat-form-field {
      width: calc(#{$total-width} - #{$search-margin-left} - #{$search-margin-right}) !important;
  }
  .search-icon-search {
      width: 0;
  }
  .search-icon-close {
      height: 0;
  }
  input {
      margin-left: $input-margin-left;
  }
  .mat-form-field-label-wrapper {
      margin-left: $input-margin-left;
  }
}

.hidden-input {
    display: none !important;
}

.administration {
  color: $font-color;
  &-toolbar {
    font-size: $font-icon-size;
    font-weight: $font-normal-weight !important;
    background-color: $color-selected;
    height: auto !important;
    padding: 0 16px;
    padding-top: 2px;
    margin-left: 2px !important;
    border-bottom: 2px solid $color-disabled;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: .005em !important;
    @include ellipsis();

    .tools {
      padding-top: 2px;
      min-height: 35px;
      height: 35px;
      max-height: 35px;
      border-bottom: 2px solid $grey;
    }
  
    &-main, &-search {
      padding-right: 0;
      color: $font-color;
  
      mat-icon {
        color: $font-color-light !important;
      }
    }
  
    &-main {
      background-color: $color-selected;
      &-subtitle {
        font-size: 12px;
        margin-left: 5px;
        color: $font-color-light;
        &-section {
          margin-left: 11px;
        }
      }
    }
  
    &-main-title {
      @include ellipsis();
    }
  }

  &-form-head {
    text-align: center;
    height: 200px;
    &-image {
      $image-size: calc(200px - 50px);
      height: $image-size;
      &-cropper {
        margin: auto;
        $cropper-width: 440px;
        img {
          max-width: $cropper-width !important;
          max-height: $image-size !important;
        }
      }
      .customer-image {
        width: 100% !important;
      }
      img {
        height: $image-size !important;
        width: $image-size !important;
        border-radius: 0 !important;
      }
    }
    &-text {
      height: 50px;
      padding: 5px;
      font-weight: $font-semi-bold;
      font-size: 17px;
      display: inline-flex;
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(center);
      &-no-height {
        height: 0;
      }
      &-title {
        color: grey;
        font-weight: $font-normal-weight;
      }
      &-subtext {
        white-space: pre;
        text-transform: lowercase;
        font-weight: $font-semi-bold;
      }
    }
  }
}

.administration-form {
  .dragover-dark {
    img, canvas, .administration-form-head-image-placeholder {
      border: 2px dashed $agent-primary;
    }
  }
}

app-profile {
  height: calc(100% - 48px);
}

.list-row-main-content {
  @include flexbox();
  @include flex-direction(row);
}
.list-row-block {
    width: 100%;
}

.flex-10 {
  width: 100%;
  max-width: 10%;
}
.flex-15 {
  width: 100%;
  max-width: 15%;
}
.flex-20 {
  width: 100%;
  max-width: 20%;
}
.flex-30 {
  width: 100%;
  max-width: 30%;
}
.flex-40 {
  width: 100%;
  max-width: 40%;
}
.flex-50 {
  width: 100%;
  max-width: 50%;
}
.flex-70 {
  width: 100%;
  max-width: 70%;
}
.flex-100 {
  width: 100%;
  max-width: 100%;
}
.flex-100-vertical {
  height: 100%;
  max-height: 100%;
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.list {
  color: $font-color;
  padding: 0 10%;
  &-fixed-width {
    width: 80%;
  }
  @media screen and (max-width: $screen-md) { padding: 0; }
  .list-row-title {
    color: $font-color-light;
    &:hover { cursor: auto !important; }
    .list-row-content:hover {
      .list-row-main-content {
        border-left: 1px solid $grey !important;
        background: white !important;
      }
    }
    .list-row-block-generic {
      position: relative;
      .list-row-block-generic-name {
        position: relative;
        mat-icon {
          display: none;
        }
        &:hover {
          mat-icon {
            display: inline-block;
            position: absolute;
            margin-left: 5px;
            top: -4px;
            cursor: pointer;
          }
        }
        span:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .list-loading-bar {
    width: 95%;
    margin-left: 2.5%;
    height: 5px;
  }

  .list-row {
    display: inline-flex;
    justify-content: center;
    outline: 0;
    width: 100%;

    &:hover { cursor: pointer; }

    .json-formatter-row {
      overflow: scroll;
      @include display-scrollbar();
    }

    .list-row-content {
      width: 95%;
      font-size: 12px;
      -webkit-transition: all 0.4s, width 0.3s;
      transition: all 0.4s, width 0.3s;

      &.is-open {
        width: 98%;
        margin: 15px 0;
        font-size: 14px;
        .list-row-main-content { display: none; }
        .list-row-detailed-content { display: block; }
      }

      .list-row-main-content {
        position: relative;
        padding: 15px;
        background-color: white;
        border: 1px solid $grey;
        border-bottom: 0;
        @include shadow();
        @include vertical-align();

        &.conversations-list {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        &:hover {
          background-color: $color-selected;
          .list-row-block-right {
            display: block;
            mat-icon { color: $agent-primary; }
          }
        }
        .list-row-block {
          @include ellipsis();
        }

        &.agents-list mat-icon {
          @include icon-size(18px);
          margin-right: 9px;

          &.blocked {
            color: $red-error;
          }

          &.test-agent {
            @include icon-size(16px);
            padding: 1px;
            border-radius: 100%;
            color: $font-color;
            background-color: $banner-color;
          }
        }

        .list-row-block-right {
          display: none;
          position: absolute;
          padding-top: 8px;
          right: 0;
          top: 0;
          overflow: hidden;
          background-color: $color-selected;
          .material-icons {
            color: $agent-primary;
          }
        }
      }

      .list-row-detailed-content {
        border-radius: 2px;
        display: none;
        background-color: white;
        @include shadow();

        .list-row-detailed-content-head {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
          padding: 5px 0px 5px 10px;
          color: white;
          font-size: 1.3rem;
          background-color: $agent-primary;
          .mat-icon-button {
            margin: 0;
            mat-icon { color: white !important; }
          }
          &-number {
            max-width: calc(100% - 140px);
            @include ellipsis();
          }
        }

        .list-row-detailed-content-body {
          .detailed-content-body-picture {
            width: 200px;
            height: 110px;
            padding: 10px;
            initials, img {
              width: 100%;
              height: 100%;
            }
          }
          .detailed-content-body-informations {
            padding: 10px 10px 10px 0;
            .detailed-content-body-information {
              border: 1px solid $color-selected;
              border-bottom: 0;
              &:last-child {
                border: 1px solid $color-selected;
              }
              .detailed-content-body-information-title, .detailed-content-body-information-content {
                border-bottom: 0;
                border-left: 0;
                padding: 5px;
                @include ellipsis();
              }
              .detailed-content-body-information-title {
                width: 22%;
                background-color: $color-background;
                font-weight: 300;
                font-size: 0.8rem;
                padding-left: 20px;
              }
              .detailed-content-body-information-content {
                width: 85%;
              }
            }
          }
        }
      }
    }
  }
}

.administration-selectable-list {
  width: 100%;

  .content-datas {
      height: 100%;
      padding: 0 !important;
  }

  .content-datas-logs {
      height: calc(100vh - 35px - 81px - 30px - #{$banner-height});
      &.logs-agent {
          height: calc(100vh - 35px - 81px - 30px - 48px - #{$banner-height});
      }

      .logs-list {
          padding-left: 0;
          padding-right: 0;
          width: 100% !important;
          margin: 0;
      }

      .list-row-title {
          height: 51px;
      }

      .editor-content {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0;
          height: 100%;
      }

      .is-selected-log {
          background-color: $color-selected !important;
      }

      .json-document {
          margin-top: 20px;
          margin-left: 15px;
          margin-bottom: 20px;
      }

      .log-is-shown {
          height: 100%;
      }

      .log-scrolling {
          height: calc(100% - 56px);
          display: block;
          position: relative;
          overflow: auto;

          &-border-content {
              border-bottom: 1px solid $grey  !important;
          }
      }

      .tab-title {
          md-pagination-wrapper { margin-left: 50px; }
          md-tabs-wrapper {
              background-color: white;
              border-bottom: 2px solid $grey  !important;
              border-top: 1px solid $grey  !important;

              md-tab-item {
                  text-transform: capitalize;
                  font-size: 12px;
                  font-weight: 400;
              }
          }
      }

      .border-title {
          border-bottom: 2px solid $grey  !important;
          > div {
              box-shadow: none !important;
          }
      }

      .first-filters {
          flex: 12.5;
          margin-top: 6px;
      }

      .second-filters {
          flex: 50;
      }

      .calls-filters-row {
          .button-sort {
              color: $agent-primary;
          }
      }

      .logs-content {
          padding-top: 0;
          padding-bottom: 0;
      }

      .sort-position {
          margin-top: 26px;
      }
  }
}

$heightOfFirstCallFilterRow : 75px;
.calls-filters {
  &-row {
    @include flexbox();
    @include flex-direction(row);
    position: relative;
    padding: 0 15px;
    margin-bottom: 5px;
    &.first-row{
      height: $heightOfFirstCallFilterRow ;
    }
    &-more-text {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 11px;
      padding: 5px 15px;
      cursor: pointer;
      span { &:hover { text-decoration: underline; }}
      &-reset {
        position: absolute;
        top: -57px;
        right: -3px;
      }
    }
    &-container {
      @include flexbox();
      @include align-items(flex-end);
      width: 100%;
      max-width: 100%;
      padding: 0 8px;
      padding-bottom: 25px;
      vertical-align: top;
      font-size: 12px;
      &-small { max-width: 260px; }
      &-medium { max-width: 620px; }
      > :first-child {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
      }
      .cy-date-filter {
        margin-top: 16px;
        margin-bottom: 0;
      }
      .text-danger {
        height: 0;
      }
      app-enum-filter {
        position: relative;
        top: 1px;
        .mat-form-field-flex {
          position: relative;
          top: 11px;
          background-color: transparent !important;
          .mat-select-value {
            position: relative;
            top: -6px;
          }
        }
        .mat-form-field-should-float .mat-form-field-label-wrapper {
          margin-top: -8px;
        }
      }
    }
  }
  &-more {
    transition: height 1s;
    height: 0;
    overflow: hidden;
    border-bottom: 3px solid $grey;
    .calls-filters-row {
      border-top: 3px solid $grey;
    }
    &.is-open {
      height: 268px;
      &-desk { height: 78px;}
    }
  }
}

.reset-filters {
  padding: 0;
  top: 5px;
  right: 20px;
  color: rgba(0, 0, 0, 0.55);
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.no-focus-overlay {
  .mat-button-focus-overlay {
    display: none;
  }
}