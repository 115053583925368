// Admin
$primary: #ad3278;
$user-primary: $primary;
$accent: #8bc34a;
$user-accent: $accent;
$dark-primary: #661d47;
$light-primary: #e9b3d2;
$lightest-primary: lighten($primary, 50%);
$dark-accent: #689f38;

// Agent
$agent-primary: #3f51b5;
$agent-dark-primary: #283593;
$agent-light-primary: #9FA8DA;
$agent-lightest-primary: lighten($agent-primary, 46%);
$agent-accent: #ff0091;
$agent-light-accent: #fff7fa;
$agent-dark-accent: #BF3364;

// Background
$color-background: #fafafa;
$color-selected: #f5f5f5;
$item-selected: #eaeaea;
$color-disabled: #e7e7e7;
$loader-background: rgba(255,255,255,0.85);

// Status
$color-available: $user-accent;
$color-break: #f6881c;
$color-outgoing: #5a43e0;
$color-incoming: #33a0e9;
$color-meeting: #8b572a;
$color-roaming: #3d71cb;
$color-on-call: $primary;

// Status Menu
$dark-available-background: $user-accent;
$dark-on-call-background: $user-primary;
$dark-on-break-background: #F7931E;
$dark-outgoing-background: #3b5998;
$dark-meeting-background: #795548;
$dark-available-background-selected: #70ac2a;
$dark-on-break-background-selected: #e47900;
$dark-outgoing-background-selected: #1a3a7e;
$dark-meeting-background-selected: #5d3628;
$dark-ringing-background: #29abe2;

// Queue colors
$color-queue-warning-dark: #ef8107;
$color-queue-warning-light: #fdfad0;
$color-queue-enough-agents-dark: #205a24;
$color-queue-enough-agents-light: #d6f6e4;

// Login
$color-login-day: #70d7fc;
$color-login-eve: #f5cc08;
$color-login-night: #655d75;

// WhatsApp
$whatsapp: #25d366;
$closed: #999c9d;
$open: #294ba8;
$new: #008273;
$unactive: #757575;
$current-message: grey;
$close: grey;
$seen-log: $color-incoming;
$no-warning-expire: rgb(128, 128, 128);

$default-file: $agent-primary;
$image: $agent-accent;
$video: $seen-log;
$music: orange;
$powerpoint: #d14426;
$word: #2a5599;
$excel: #02723b;
$pdf: #f91d0a;

// SMS
$sms: #f7a500;

// General
$red-error: rgb(221,44,0);
$grey: rgb(231,231,231);
$light-grey: lighten($grey, 2%);
$dark-grey: rgb(201,201,201);
$darkest-grey: #666;
$banner-color: #ffe118;